import React, { useCallback, useEffect, useState } from "react";
import Filters from "../Filters/filters";
import machinerawdata from "../Machines/machinerawdata"; // Import machines data
import Table from "../../../shared/components/organisms/table/Table";
import { fetchAllBW5ApplicationData } from "../../../shared/redux/getBW5ApplicationListSlice";
import { fetchAllBW6ApplicationData } from "../../../shared/redux/getBW6ApplicationListSlice";
import { useDispatch, useSelector } from "react-redux";
import DashboardBar from "../../../shared/components/organisms/dahboardBar/DashboardBar";
import { applyFilters } from "../Filters/helpers";
import { useNavigate } from "react-router-dom";
import CustomModel from "../CustomModel/customModel";
import { domainColumns } from "../Domains/constant";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "bg-secondary text-left h-12 uppercase text-white sticky top-0 z-10",
  th: "px-4",
  tr: "justify-between flex-grow mb-2",
  td: "max-w-60 min-w-8 truncate text-ellipsis text-left text-sm py-2 px-4 align-middle border-b border-secondary-100",
};

const initialFilters = [
  {
    type: "TEXT",
    key: "ApplicationName",
    placeholder: "Name",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "Status",
    placeholder: "Status",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "DomainName",
    placeholder: "Domain",
    value: "",
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.bw5ApplistData?.data);
  const _bw6data = useSelector((state) => state.bw6ApplistData?.data);
  const domainData = useSelector((state) => state?.domainData?.data);
  const [rowData, setRowData] = useState([]);
  const [bw6Data, setbw6Data] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [dropdownValues, setDropdownValues] = useState({});
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setRowData(data);
    setbw6Data(_bw6data);
    const Status = new Set();
    const DomainName = new Set();
    data?.forEach((item) => {
      Status.add(item.Status);
      DomainName.add(item.DomainName);
    });
    _bw6data?.forEach((item) => {
      Status.add(item.Status);
      DomainName.add(item.DomainName);
    });
    setDropdownValues({ Status, DomainName });
  }, [data]);

  useEffect(() => {
    const bw5FilteredData = applyFilters(filters, data);
    setRowData(bw5FilteredData);
    const bw6FilteredData = applyFilters(filters, _bw6data);
    setbw6Data(bw6FilteredData);
  }, [filters]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = (domain) => {
    let selectedDomain = domainData.find((obj) => obj.DomainId === domain);
    setSelectedDomain(selectedDomain);
    setIsModalOpen(true);
  };

  const bw5columns = React.useMemo(
    () => [
      {
        Header: "BW5 Application",
        accessor: "ApplicationName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() =>
                navigate(`/d/bw5applications/${row.original.ApplicationId}`)
             }
          >
            {row.original.ApplicationName}
          </button>
        ),
      },
      {
        Header: "Status",
        accessor: "Status",
        Cell: ({ row }) => (
          <div className={`rounded-md w-16 p-1 text-center text-white ${
            row.original.Status === "Running"
              ? "bg-green-500"
              : row.original.Status === "Partial Running" ?
                     "bg-blue-500 scroll-container"
                    : "bg-red-500"
          }`}>
          <div className={`${
          row.original.Status === "Partial Running"?
                    "scroll-text"
                    : ""
                    }`}>
            {row.original.Status ? row.original.Status : "Stopped"}
          </div>
          </div>
        ),
      },
      {
        Header: "Domain",
        accessor: "DomainName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() => handleOpenModal(row.original.DomainId)}
          >
            {row.original.DomainName}
          </button>
        ),
      }
    ],
    []
  );

  const bw6columns = React.useMemo(
    () => [
      {
        Header: "BW6 Application",
        accessor: "ApplicationName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() =>
                navigate(`/d/bw6applications/${row.original.ApplicationId}`)
             }
          >
            {row.original.ApplicationName}
          </button>
        ),
      },
      {
        Header: "Status",
        accessor: "Status",
        Cell: ({ row }) => (
          <div className={`rounded-md w-16 p-1 text-center text-white ${
            row.original.Status === "Running"
              ? "bg-green-500"
              : row.original.Status === "Partial Running" ?
                     "bg-blue-500 scroll-container"
                    : "bg-red-500"
          }`}>
          <div className={`${
          row.original.Status === "Partial Running"?
                    "scroll-text"
                    : ""
                    }`}>
            {row.original.Status ? row.original.Status : "Stopped"}
          </div>
          </div>
        ),
      },
      {
        Header: "Domain",
        accessor: "DomainName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() => handleOpenModal(row.original.DomainId)}
          >
            {row.original.DomainName}
          </button>
        ),
      }
    ],
    []
  );

  const handleFilterChange = (value, index) => {
    const cloned = JSON.parse(JSON.stringify(filters));
    const obj = cloned[index];
    obj.value = value;
    setFilters(cloned);
  };

  const onFilterReset = () => {
    setFilters(initialFilters);
  };

  const fetchData = useCallback((pageIndex) => {
    dispatch(fetchAllBW5ApplicationData(pageIndex));
    dispatch(fetchAllBW6ApplicationData(pageIndex));
    setPageCount(pageCount + 1);
  }, []);

  return (
    <div className="h-screen flex flex-col">
      <DashboardBar />
      <div className="flex-grow">
        <Filters
          filters={filters}
          onFilterChange={handleFilterChange}
          onFilterReset={onFilterReset}
          dropdownValues={dropdownValues}
        />
        <div className="flex gap-2 mt-4">
        <div className="bg-white rounded-lg p-2 shadow-md flex-grow">
        <div className="h-full rounded-md">
          <div className="h-full max-h overflow-y-auto">
          <Table
            columns={bw5columns}
            data={rowData}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        </div>
        </div></div>
        <div className="bg-white rounded-lg p-2 shadow-md flex-grow">
        <div className="h-full rounded-md">
          <div className="h-full max-h overflow-y-auto">
          <Table
            columns={bw6columns}
            data={bw6Data}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        </div>
        </div></div>
        </div>
      </div>
      <CustomModel
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedDomain}
        columns={domainColumns}
        title={selectedDomain?.DomainName}
      />
    </div>
  );
};

export default Dashboard;
