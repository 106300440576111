import React from 'react'

const BasicCard = ( {children, backgroundColor, width, height, innterPading} ) => {
  return (
    <div className={`${width ? width : 'max-w-sm min-w-60'} mx-auto ${backgroundColor ? backgroundColor : 'bg-white'} shadow-md rounded-lg overflow-hidden ${height ? height : 'h-60'}`}>
    <div className={`${innterPading? innterPading: 'p-3'}`}>
    {children}
    </div>
    </div>
  )
}

export default BasicCard