import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip, Button } from "@mui/material";
import ComputerIcon from "@mui/icons-material/Computer";
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import { setSidebarVisible } from "../../redux/store";
import DomainModal from "../../../components/Discovery/Domains/DomainModal";
import MachineModal from "../../../components/Discovery/Machines/MachineModal";
import { fetchSidebarData } from "../../redux/getSidebarDataSlice";
import TibcoIcon from "../../../assests/Image.png";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';

const DSidebar = ({ setSidebarVisible }) => {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const data = useSelector((state) => state.sidebarData?.data);

  const [activeItem, setActiveItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [activeSubItem, setActiveSubItem] = useState(null);
  const [showAllSubItems, setShowAllSubItems] = useState(false);

  const [openMenus, setOpenMenus] = useState({BW5: true, BW6: true});
  const [openSubmenus, setOpenSubmenus] = useState({});

  const toggleMenu = (productType) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [productType]: !prevState[productType],
    }));
    console.log(openMenus,"open")
  };

  const toggleSubmenu = (productType, submenu) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [productType]: {
        ...prevState[productType],
        [submenu]: !prevState[productType]?.[submenu],
      },
    }));
  };

  const sidebars= useSelector(
    (state) => state.sidebarData
  );

  const { domains, data: domainsData } = useSelector(
    (state) => state.domainData
  );
  const { machines, data: machinesData } = useSelector(
    (state) => state.machineData
  );
  const applications = useSelector(
    (state) => state.applicationsData
  );

  const DSIDEBAR_ITEMS = [
    {
      text: "DOMAINS",
      icon: DnsOutlinedIcon,
      route: "/d/domains",
      subItems: domains,
    },
    {
      text: "MACHINES",
      icon: ComputerIcon,
      route: "/d/machines",
      subItems: machines,
    },
    {
      text: "BW5 APPLICATIONS",
      icon: NotificationsRoundedIcon,
      route: "/d/bw5applications",
      subItems: applications,
    },
    {
      text: "BW6 APPLICATIONS",
      icon: NotificationsRoundedIcon,
      route: "/d/bw6applications",
      subItems: applications,
    },
  ];
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOnClickMore = (type) => {
    switch(type) {
      case 'domains':
        navigate('/d/domains/')
        break;
      case 'Machines':
        navigate('/d/bw5applications/')
        break;
      case 'BW5':
        navigate('/d/bw5applications')
        break;
      case 'BW6':
        navigate('/d/bw6applications')
        break;
      default:
        break;
    }
  };
  const handleItemClick = (item, type, productType) => {
    if (type === "domain") {
      // let selectedDomain = domainsData.find(obj => obj.DomainName === item);
      setActiveSubItem(item.DomainName);
      setActiveItem({ text: "DOMAINS" });
      handleOpenModal();
    } else if (type === "machine") {
      // let selectedMachine = machinesData.find(obj => obj.ServerName === item);
      setActiveSubItem(item.ServerName);
      setActiveItem({ text: "MACHINES" });
      handleOpenModal();
    } else if (type === "application") {
      if(productType==="BW5"){
        navigate('/d/bw5applications/'+item?.ApplicationId)
      }else if(productType==="BW6"){
        navigate('/d/bw6applications/'+item?.ApplicationId)
      }
    }
    // setActiveItem(activeItem === item ? null : item); // Toggle active item
    // setSidebarVisible(true);
    // Hide the sidebar after clicking an item
  };

  // Determine if sidebar should be visible based on current route
  const shouldShowSidebar = location.pathname.startsWith("/d");

  useEffect(() => {
    dispatch(fetchSidebarData());
  }, []);

  return (
    <div
      className={`fixed mt-16 h-full flex px-4 ${
        shouldShowSidebar ? "" : "hidden"
      } border-r-[1px] mr-4 shadow-[rgba(0,0,15,0.1)_5px_0px_5px_0px]`}
    >
      <div className="text-green-600 w-56 mt-10">
      <ul className="space-y-6">
        {data && data.map((menu) => (
          <div key={menu.productType}>
            <h3
              className="flex gap-2 items-center cursor-pointer font-bold te"
              onClick={() => toggleMenu(menu.productType)}
            >
            <img src={TibcoIcon} alt="tibco-logo" className="w-5 h-5 mb-1" style={{filter:"revert"}}/>  {menu.productType}
            </h3>
            {openMenus[menu.productType] && (
              <ul className="ml-8 space-y-4">
                <li>
                  <h4
                    className="cursor-pointer font-semibold"
                    onClick={() => toggleSubmenu(menu.productType, 'domains')}
                  >
                   <DnsOutlinedIcon/> Domains {openSubmenus[menu.productType]?.domains?<ExpandLessIcon style={{fontSize: 'medium'}} />:<ExpandMoreIcon style={{fontSize: 'medium'}} />}
                  </h4>
                  {/* <div className="ml-3 border-l border-primary"> */}
                  {openSubmenus[menu.productType]?.domains && (
                    <div className="ml-2 p-1 cursor-pointer text-primary">
                      {/* {menu.domains.map((domain, index) => (
                        <>
                        <div className="flex items-center gap-2">
                        <span className={`w-4 ${index===0?'h-4':'h-10'} border-l border-b border-primary mt-[-5px]`} style={{borderBottomLeftRadius:'6px'}}></span>
                        <span className={`${index===0?'mb-[-10px]':'mb-[-35px]'}`}  key={index} onClick={() => handleItemClick(domain,'domain', menu.productType)}>{domain.DomainName}</span>
                        </div>
                        </>
                      ))} */}

                      {menu.domains.slice(0, 2).map((domain, index) => (
                        <>
                        <div className="flex items-center gap-2">
                        <span className={`w-4 ${index===0?'h-4':'h-10'} border-l border-b border-primary mt-[-5px]`} style={{borderBottomLeftRadius:'6px'}}></span>
                        <span className={`${index===0?'mb-[-10px]':'mb-[-35px]'}`}  key={index} onClick={() => handleItemClick(domain,'domain', menu.productType)}>{domain.DomainName}</span>
                        </div>
                        </>
                      ))}
                      {menu.domains.length > 2 && (
                      <>
                        <div className="flex items-center gap-2">
                        <span className={`w-4 h-10 border-l border-b border-primary mt-[-5px]`} style={{borderBottomLeftRadius:'6px'}}></span>
                        <span className={`mb-[-35px]`} onClick={() => handleOnClickMore('domains')}>See More <ReadMoreIcon/></span>
                        </div>
                        </>
                    )}
                    </div>
                  )}
                  {/* </div> */}
                </li>
                <li>
                  <h4
                    className="cursor-pointer font-semibold"
                    onClick={() => toggleSubmenu(menu.productType, 'machines')}
                  >
                  <ComputerIcon/>  Machines {openSubmenus[menu.productType]?.machines?<ExpandLessIcon style={{fontSize: 'medium'}} />:<ExpandMoreIcon style={{fontSize: 'medium'}} />}
                  </h4>
                  {openSubmenus[menu.productType]?.machines && (
                    <div className="ml-2 p-1 cursor-pointer text-primary">
                      {menu.servers.slice(0, 2).map((machine, index) => (
                        <>
                        <div className="flex items-center gap-2">
                        <span className={`w-4 ${index===0?'h-4':'h-10'} border-l border-b border-primary mt-[-5px]`} style={{borderBottomLeftRadius:'6px'}}></span>
                        <span className={`${index===0?'mb-[-10px]':'mb-[-35px]'}`} key={index} onClick={() => handleItemClick(machine,'machine',menu.productType)}>{machine.ServerName}</span>
                        </div>
                        </>
                      ))}
                      {menu.servers.length > 2 && (
                      <>
                        <div className="flex items-center gap-2">
                        <span className={`w-4 h-10 border-l border-b border-primary mt-[-5px]`} style={{borderBottomLeftRadius:'6px'}}></span>
                        <span className={`mb-[-35px]`} onClick={() => handleOnClickMore('servers')}>See More <ReadMoreIcon/></span>
                        </div>
                        </>
                    )}
                    </div>
                  )}
                </li>
                <li>
                  <h4
                    className="cursor-pointer font-semibold"
                    onClick={() => toggleSubmenu(menu.productType, 'applications')}
                  >
                  <AppsIcon/>  Applications {openSubmenus[menu.productType]?.applications?<ExpandLessIcon style={{fontSize: 'medium'}} />:<ExpandMoreIcon style={{fontSize: 'medium'}} />}
                  </h4>
                  {openSubmenus[menu.productType]?.applications && (
                    <div className="ml-2 p-1 cursor-pointer text-primary">
                      {menu.applications.slice(0, 2).map((application, index) => (
                        <>
                        <div className="flex items-center gap-2">
                        <span className={`w-4 ${index===0?'h-4':'h-10'} border-l border-b border-primary mt-[-5px]`} style={{borderBottomLeftRadius:'6px'}}></span>
                        <span className={`${index===0?'mb-[-10px]':'mb-[-35px]'}`} key={index} onClick={() => handleItemClick(application,'application', menu.productType)}>{application.ApplicationName}</span>
                        </div>
                        </>
                      ))}
                    {menu.applications.length > 2 && (
                      <>
                        <div className="flex items-center gap-2">
                        <span className={`w-4 h-10 border-l border-b border-primary mt-[-5px]`} style={{borderBottomLeftRadius:'6px'}}></span>
                        <span className={`mb-[-35px]`} onClick={() => handleOnClickMore(menu.productType)}>See More <ReadMoreIcon/></span>
                        </div>
                        </>
                    )}
                    </div>
                  )}
                </li>
              </ul>
            )}
          </div>
        ))}
      </ul>
    </div>

      <DomainModal
        open={activeItem?.text === "DOMAINS" && openModal}
        onClose={handleCloseModal}
        data={domainsData.find((dom) => dom.DomainName === activeSubItem)}
      />

      <MachineModal
        open={activeItem?.text === "MACHINES" && openModal}
        onClose={handleCloseModal}
        data={machinesData.find((mach) => mach.ServerName === activeSubItem)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSidebarVisible: state.navigation.isSidebarVisible,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarVisible: (isVisible) => dispatch(setSidebarVisible(isVisible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DSidebar);
