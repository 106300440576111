import React from 'react'
import { Link } from 'react-router-dom'
import AppUtils from '../../../utils/AppUtils'
import { Divider } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';

const AlertItem = ({item}) => {
  return (
    <>
      <div className='flex px-4 py-3 bg-gray-50 cursor-pointer items-center'>
        <div className={`mr-2 ${item.AlertSeverity==='critical'?'text-red-400':'text-yellow-400'} text-center`}>
        <ErrorIcon />
        </div>
        <Link to={item.AlertDetailURL}>
        <p className='text-black flex-grow text-xs font-semibold'>{item.AlertName} <span className='text-gray-400 flex-grow text-xs font-thin'>
       {item.AlertDescription != null &&
                          (item.AlertDescription.length > 26
                            ? item.AlertDescription.substring(0, 26) + "..."
                            : item.AlertDescription)}</span></p>
        <p className='text-gray-400 flex-grow text-xs font-thin'>{AppUtils.getTimeDate(item.AlertCreatedTimestamp)}</p>
        </Link>
        
    </div>
    <Divider />
    </>
  )
}

export default AlertItem