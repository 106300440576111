import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AppConstants } from "./constants";
import GreenfieldIcon from "../assests/greenfield.png";
import TibcoIcon from "../assests/tibco_logo.png";
import BoomiIcon from "../assests/boomi_logo.png";
import MulesoftIcon from "../assests/mulesoft_logo.png";

const { Completed, Failed, Created, InProgress } = AppConstants.PROJECT_STATUS;

class AppUtils {
  static getStatusClass = (status) => {
    switch (status) {
      case Completed:
        return "border-green-300 text-green-800 bg-green-100";
      case Failed:
        return "border-red-300 text-red-800 bg-red-100";
      case Created:
        return "border-blue-300 text-blue-800 bg-blue-100";
      case InProgress:
        return "border-pink-300 text-pink-800 bg-pink-100";
      default:
        return "border-gray-300 text-gray-800 bg-gray-100";
    }
  };

  static getStatusBgColor = (status) => {
    switch (status) {
      case Completed:
        return "bg-green-100";
      case Failed:
        return "bg-red-100";
      case Created:
        return "bg-blue-100";
      case InProgress:
        return "bg-yellow-500";
      default:
        return "bg-gray-100";
    }
  };

  static getStatusDarkBgColor = (status) => {
    switch (status) {
      case Completed:
        return "bg-green-500";
      case Failed:
        return "bg-red-500";
      case Created:
        return "bg-blue-500";
      case InProgress:
        return "bg-yellow-500";
      default:
        return "bg-gray-500";
    }
  };

  static getStatusTextColor = (status) => {
    switch (status) {
      case Completed:
        return "text-green-800";
      case Failed:
        return "text-red-800";
      case Created:
        return "text-blue-800";
      case InProgress:
        return "text-yellow-800";
      default:
        return "text-gray-800";
    }
  };
  static getTime = (time) => moment.utc(time).local().fromNow();
  static getTimeDate = (date) => moment(date).format("LLL");
  static getFormattedDate = (date) => moment(date).format("DD MMM YY");
  static getTimeDiff = (startTime, endTime) => {
    const endMoment = moment(endTime);
    const startMoment = moment(startTime);

    const processingTimeInMillis = endMoment.diff(startMoment);

    const duration = moment.duration(processingTimeInMillis);

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let processingTime = "";
    if (hours > 0) {
      processingTime += `${hours} hours `;
    }
    if (minutes > 0) {
      processingTime += `${minutes} mins `;
    }
    if (seconds > 0) {
      processingTime += `${seconds} secs`;
    }
    if (!processingTime) {
      processingTime = "< 1 sec";
    }
    return processingTime.trim();
  };

  static downloadFile(binaryData, fileName) {
    const blob = new Blob([binaryData]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  static getTitle = (job) => {
    if (job.job_type === "Greenfield") {
      let input = job?.input_content;
      let error = false;
      if (!input) return "N?A";
      if (input.includes("{")) {
        try {
          input = JSON.parse(input);
        } catch (e) {
          error = true;
        }
      }
      return error ? "Error" : input.prompt;
    }
    return "FILE";
  };
  static getIcon = (name) => {
    const currentIconItem = AppConstants.BoomiIconItems.find((icon) =>
      name.includes(icon.name)
    );
    if (currentIconItem) {
      return currentIconItem;
    }
    return { icon: InfoOutlinedIcon, className: "text-slate-500" };
  };

  static convertMinsToHoursAndMinutes = (totalMinutes) => {
    if (!totalMinutes) return 'N/A';
    let hours = parseInt(Math.floor(totalMinutes / 60));
    let minutes = parseFloat(totalMinutes % 60).toFixed(2);

    if (hours === 0) {
      return `${minutes} mins`;
    } else if (minutes === 0) {
      return `${hours} h(s)`;
    } else {
      return `${hours} h(s), ${minutes} mins`;
    }
  };

  static getPlatform = (name) =>{
    let PLATFORMS = {
      "BW5": 'Tibco bw 5.x',
      "BW6": 'Tibco bw 6.x',
      "MuleSoft": 'MuleSoft',
      "Boomi": 'Boomi',
      "JSON": ''
    }
    return PLATFORMS[name]
  }

  static getPlatformIcon = (name) =>{
    let PLATFORMS = {
      "BW5": TibcoIcon,
      "BW6": TibcoIcon,
      "MuleSoft": MulesoftIcon,
      "Boomi": BoomiIcon,
      "JSON": GreenfieldIcon
    }
    return PLATFORMS[name]
  }
}

export default AppUtils;
