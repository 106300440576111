import BasicModal from "../../../shared/components/molecules/BasicModal";
import ListItems from "../../../shared/components/molecules/ListItems";
import StorageIcon from "@mui/icons-material/Storage";
import { domainColumns } from "./constant";
import { Button } from "@mui/material";

const DomainModal = ({ open, onClose, data = {} }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <div className="flex gap-4 items-center">
        <div className="w-12 h-12 border flex justify-center items-center rounded-md">
          <StorageIcon />
        </div>
        <div>
          <p className=" font-semibold capitalize">{data?.DomainName}</p>
          <p className="text-gray-400"></p>
        </div>
        <div onClick={onClose} className="absolute top-8 right-10 font-bold  text-secondary text-lg cursor-pointer hover:text-primary">
        X
      </div>
      </div>
      <div className="w-full border p-b-1 my-4" />
      <ListItems data={data} columns={domainColumns} />
    </BasicModal>
  );
};

export default DomainModal;
