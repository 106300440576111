import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "../../../shared/components/organisms/table/Table";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Filters from "../Filters/filters";
import { applyFilters } from "../Filters/helpers";
import DomainModal from "../Domains/DomainModal";
import { fetchAllBW6ApplicationData } from "../../../shared/redux/getBW6ApplicationListSlice";
import CustomModel from "../CustomModel/customModel";
import { domainColumns } from "../Domains/constant";

const customClasses = {
  table: "flex-row-reverse w-full overflow-y rounded-md",
  thead: "bg-secondary text-center h-12 uppercase text-white sticky top-0 z-10",
  th: "px-2",
  tr: "justify-between flex-grow mb-2",
  td: "bg-white min-w-8 truncate text-ellipsis text-center text-sm py-4 px-6 align-middle border-b border-secondary-100",
};

const initialFilters = [
  {
    type: "TEXT",
    key: "ApplicationName",
    placeholder: "Name",
    value: "",
  },
  {
    type: "DROPDOWN",
    key: "DomainName",
    placeholder: "Domain",
    value: "",
  },
];

const Bw6Applications = () => {
  const location = useLocation();
  const { pathname } = location;
  const lastSlashIndex = pathname.lastIndexOf("/");
  const domainName =
    lastSlashIndex !== -1 ? pathname.substring(lastSlashIndex + 1) : null;
  const data = useSelector((state) => state?.bw6ApplistData?.data);
  console.log("app-data", data);
  const domainData = useSelector((state) => state?.domainData?.data);
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filters, setFilters] = useState(initialFilters);
  const [dropdownValues, setDropdownValues] = useState({});
  const [rowData, setRowData] = useState([]);

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Application Name",
        accessor: "ApplicationName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() => 
                navigate(`/d/bw6applications/${row.original.ApplicationId}`)
            }
          >
            {row.original.ApplicationName}
          </button>
        ),
      },
      { Header: "Description", accessor: "Description" },
      { Header: "Version", accessor: "Version" },
      {
        Header: "Status",
        accessor: "Status",
        Cell: ({ row }) => (
          <div
            className={`rounded-md w-16 p-1 text-center text-white ${
              row.original.Status === "Running"
                ? "bg-green-500"
                : row.original.Status === "Partial Running"
                      ? "bg-blue-500"
                      : "bg-red-500"
            }`}
          >
            {row.original.Status ? row.original.Status : "Stopped"}
          </div>
        ),
      },
      {
        Header: "Domain",
        accessor: "DomainName",
        Cell: ({ row }) => (
          <button
            className="hover:text-primary"
            onClick={() => handleOpenModal(row.original.DomainId)}
          >
            {row.original.DomainName}
          </button>
        ),
      },
      { Header: "Created Date", accessor: "CreationDate" },
      { Header: "Owner", accessor: "Owner" },
    ],
    []
  );

  const handleOpenModal = (domain) => {
    let selectedDomain = domainData.find((obj) => obj.DomainId === domain);
    setSelectedDomain(selectedDomain);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const DomainName = new Set();
    data?.forEach((item) => {
      DomainName.add(item.DomainName);
    });
    setDropdownValues({ DomainName });
  }, [data]);

  useEffect(() => {
    const filteredData = applyFilters(filters, data);
    setRowData(filteredData);
  }, [filters, data]);

  const handleFilterChange = (value, index) => {
    const cloned = JSON.parse(JSON.stringify(filters));
    const obj = cloned[index];
    obj.value = value;
    setFilters(cloned);
  };

  const onFilterReset = () => {
    setFilters(initialFilters);
  };
  // useEffect(() => {
  //   const intervalId = setInterval(()=>{dispatch(fetchAllBW6ApplicationData())}, 60000); // 300000ms = 5 minutes
  //   // Clear the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);
  const fetchData = useCallback((pageIndex) => {
    dispatch(fetchAllBW6ApplicationData(pageIndex));
    setPageCount(pageCount + 1);
  }, []);

  return (
    <div className="flex flex-col py-6">
      <div>
        <Link
          to="/d"
          className="text-primary hover:text-primaryLight text-lg font-medium"
        >
          Dashboard
        </Link>
        {domainName && (
          <span className="ml-2 text-primary text-lg font-medium hover:text-primaryLight">
            &gt; {domainName.charAt(0).toUpperCase() + domainName.slice(1)}
          </span>
        )}
      </div>
      <div className="self-start my-6 p-0">
        <Filters
          filters={filters}
          onFilterChange={handleFilterChange}
          onFilterReset={onFilterReset}
          dropdownValues={dropdownValues}
        />
      </div>
      <div className="bg-white rounded-lg p-2 shadow-md">
        <div className="h-full rounded-md">
          <div className="h-full max-h overflow-y-auto">
        {
          <Table
            columns={columns}
            data={rowData}
            fetchData={fetchData}
            pageCount={pageCount}
            customClasses={customClasses}
          />
        }
      </div>
      </div>
      </div>
      <CustomModel
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedDomain}
        columns={domainColumns}
        title={selectedDomain?.DomainName}
      />
    </div>
  );
};

export default Bw6Applications;
