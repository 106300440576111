import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDomainData } from "../../redux/getDomainDataSlice";
import { fetchAllMachineData } from "../../redux/getMachineDataSlice";
import SearchIcon from "@mui/icons-material/Search";
import { fetchAllBW6ApplicationData } from "../../redux/getBW6ApplicationListSlice";
import { fetchAllBW5ApplicationData } from "../../redux/getBW5ApplicationListSlice";
import MachineModal from "../../../components/Discovery/Machines/MachineModal";
import DomainModal from "../../../components/Discovery/Domains/DomainModal";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import ComputerIcon from "@mui/icons-material/Computer";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import AppsIcon from "@mui/icons-material/Apps";

const menus = [
  { label: "All", value: "All" },
  { label: "Domains", value: "domains" },
  { label: "Machines", value: "machines" },
  { label: "Applications", value: "applications" },
];

function SearchBar() {
  const dispatch = useDispatch();
  const domains = useSelector((state) => state.domainData.domains);
  const machines = useSelector((state) => state.machineData.machines);
  const bw6pplications = useSelector(
    (state) => state.bw6ApplistData.applications
  );
  const bw5pplications = useSelector(
    (state) => state.bw5ApplistData.applications
  );
  const machinedata = useSelector((state) => state.machineData?.data);
  const domainData = useSelector((state) => state.domainData?.data);
  const bw6AppData = useSelector((state) => state.bw6ApplistData.data);
  const bw5AppData = useSelector((state) => state.bw5ApplistData.data);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("All");
  const [openMenu, setOpenMenu] = useState(false);
  const inputRef = useRef(null);
  const cardRef = useRef(null);
  const [selectedMachine, setSelectedMachine] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const alerts = useSelector((state) => state.alertsData?.nodeDown);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchAllDomainData(alerts));
    dispatch(fetchAllMachineData(alerts));
  }, [dispatch, alerts]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchAllBW6ApplicationData());
      dispatch(fetchAllBW5ApplicationData());
    }, 60000);
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    }
    // Bind
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (event, value) => {
    setSearchQuery(value);
    setOpenMenu(value !== "");
  };

  const handleMenuChange = (event) => {
    setSelectedMenu(event.target.value);
    setOpenMenu(false);
  };

  const options = useMemo(() => {
    let tempDomains = domains
      ? domains.map((domain) => ({ title: "Domain", value: domain }))
      : [];
    let tempMachines = machines
      ? machines.map((machines) => ({ title: "Machines", value: machines }))
      : [];
    let tempBW5Apps = bw5pplications
      ? bw5pplications.map((app) => ({ title: "BW5-Applications", value: app }))
      : [];
    let tempBW6Apps = bw6pplications
      ? bw6pplications.map((app) => ({ title: "BW6-Applications", value: app }))
      : [];
    if (selectedMenu === "domains") {
      return tempDomains.filter((val) =>
        val.value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (selectedMenu === "machines") {
      return tempMachines.filter((val) =>
        val.value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (selectedMenu === "applications") {
      return [...tempBW6Apps, ...tempBW5Apps].filter((val) =>
        val.value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return [
      ...tempDomains,
      ...tempMachines,
      ...tempBW6Apps,
      ...tempBW5Apps,
    ].filter((val) =>
      val.value.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [
    selectedMenu,
    domains,
    machines,
    bw6pplications,
    bw5pplications,
    searchQuery,
  ]);

  const handleOptionsClicked = (e, value) => {
    console.log(e.target.value, value);
    setSearchQuery(value)
    if (domains.includes(value)) {
      console.log("domain");
      let selectedDomain = domainData.find((obj) => obj.DomainName === value);
      setSelectedDomain(selectedDomain);
      setIsDomainModalOpen(true);
    } else if (bw6pplications.includes(value)) {
      let selectedApp = bw6AppData.find((obj) => obj.ApplicationName === value);
      navigate(`/d/bw6applications/${selectedApp?.ApplicationId}`);
    } else if (bw5pplications.includes(value)) {
      let selectedApp = bw5AppData.find((obj) => obj.ApplicationName === value);
      navigate(`/d/bw5applications/${selectedApp?.ApplicationId}`);
    } else if (machines.includes(value)) {
      console.log("machine");
      let selectedMachine = machinedata.find((obj) => obj.ServerName === value);
      setSelectedMachine(selectedMachine);
      setIsModalOpen(true);
    }
    setOpenMenu(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsDomainModalOpen(false);
  };

  return (
    <div className="flex items-center justify">
      <div
        className="flex gap-2 items-center ml-2"
        style={{ boxSizing: "border-box" }}
      >
        <SearchIcon
          style={{
            color: "#fff",
            marginRight: "-40px",
            border: "white",
            zIndex: "100",
          }}
          className="h-6 w-6 font-bold z-20"
        />
        <div className="flex items-center">
          <input
          ref={inputRef}
            type="text"
            value={searchQuery}
            className="h-10 w-96 bg-[#ffffff33] drop-shadow-md rounded-lg backdrop-blur-lg outline-none text-white py-4 pr-4 pl-8 focus:border-2 focus:border-[#ffffff44] hover:border-2 hover:border-[#ffffff44] shadow-md"
            onChange={(event) => handleSearchChange(event, event.target.value)}
          />
            <div onClick={()=>{setSearchQuery(''); console.log('cli')}} className={`ml-[-150px] text-white text-lg cursor-pointer z-20 ${inputRef.current === document.activeElement?'visible':'invisible'}`}>
        x
      </div>
        </div>
        <div className="border-l-2 border-slate-500">
          <select
            value={selectedMenu}
            onChange={handleMenuChange}
            className="h-8 outline-none bg-secondary rounded-lg backdrop-blur-sm text-white font-semibold px-2 drop-shadow-md shadow-md"
          >
            {menus.map(({ label, value }) => (
              <option style={{ padding: "50px" }} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      {openMenu && (
        <Card
          ref={cardRef}
          variant="outlined"
          className="absolute top-14 w-96 h-80 overflow-y-auto rounded-lg z-50"
        >
          {options.map((val, i) => (
            <p
              onClick={(e) => {
                handleOptionsClicked(e, val.value);
              }}
              className="flex gap-4 px-6 py-4 text-slate-500 font-semibold cursor-pointer hover:bg-gray-50 z-50"
              key={i}
            >
              {val?.title === "Domain" && <DnsOutlinedIcon />}
              {val?.title === "Machines" && <ComputerIcon />}
              {(val?.title === "BW5-Applications" ||
                val?.title === "BW6-Applications") && <AppsIcon />}{" "}
              {val?.value}
            </p>
          ))}
        </Card>
      )}
      <MachineModal
        open={isModalOpen}
        onClose={handleCloseModal}
        data={selectedMachine}
      />
      <DomainModal
        open={isDomainModalOpen}
        onClose={handleCloseModal}
        data={selectedDomain}
      />
    </div>
  );
}

export default SearchBar;
